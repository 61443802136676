import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';

const DocuLinkSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  const { t } = useTranslation();
  return (
    <Box {...sectionWrapper} id="newsletter_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={t('Documentation')} />
          <Heading
            {...secHeading}
            content={t('Do you want to host Psono on your own?')}
          />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <div style={{ padding: '8px 0' }}>
              <div
                style={{
                  display: 'flex',
                  padding: '0 16px',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: ['15px', '15px', '15px', '15px', '15px'],
                    color: '#343d48cc',
                    lineHeight: '2',
                    mb: '33px',
                  }}
                >
                  {t(
                    'We are providing a docker image that bundles the Psono Server (Community or Enterprise Edition), webclient and admin portal, that allows you to host Psono on your own server. An installation guide can be found here:'
                  )}
                </p>
              </div>
            </div>
            <Box {...buttonWrapper}>
              <a
                target="_blank"
                rel="noreferrer"
                className="clickable_link"
                href="https://doc.psono.com/admin/installation/install-psono-ce.html"
              >
                <Button
                  {...button}
                  title={t('Install Psono Community Edition')}
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="clickable_link"
                href="https://doc.psono.com/admin/installation/install-psono-ee.html"
              >
                <Button
                  {...button}
                  title={t('Install Psono Enterprise Edition')}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DocuLinkSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

DocuLinkSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    marginLeft: '10px',
    marginRIght: '10px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default DocuLinkSection;
