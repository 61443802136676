import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const DownloadSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  titleText,
  descriptionText,
  downloadItems,
  row,
  col,
  FeatureItemStyle,
  iconStyle,
  contentStyle,
  featureTitle,
  featureDescription,
  button,
}) => {
  const { t } = useTranslation();
  return (
    <Box {...sectionWrapper} as="section" id="feature_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={titleText} />
          <Heading {...secHeading} content={descriptionText} />
        </Box>

        <Box {...row}>
          {downloadItems.map((item, index) => (
            <Box {...col} key={`feature-item-${index}`}>
              <FeatureBlock
                icon={
                  item.url ? (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <Image
                        src={item.icon.publicURL}
                        alt={`feature-item-icon-${index + 1}`}
                      />
                    </a>
                  ) : (
                    <Image
                      src={item.icon.publicURL}
                      alt={`feature-item-icon-${index + 1}`}
                    />
                  )
                }
                wrapperStyle={FeatureItemStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                iconPosition="left"
                title={<Heading content={item.title} {...featureTitle} />}
                description={
                  <div>
                    <Text
                      content={t(item.description)}
                      {...featureDescription}
                    />
                    {item.installGuideUrl && (
                      <div style={{ marginTop: '20px' }}>
                        <a
                          href={item.installGuideUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button {...button} title="Install Guide" />
                        </a>
                      </div>
                    )}
                  </div>
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

DownloadSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  titleText: PropTypes.string,
  downloadItems: PropTypes.array,
  descriptionText: PropTypes.string,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

DownloadSection.defaultProps = {
  sectionWrapper: {
    pt: ['50px', '50px', '50px', '50px', '50px'],
    pb: ['20px', '50px', '60px', '70px', '100px'],
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-30px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-30px', '-45px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pl: ['30px', '30px', '30px', '30px', '45px'],
    pr: ['30px', '30px', '30px', '30px', '45px'],
    mb: ['50px', '70px'],
  },
  FeatureItemStyle: {
    alignItems: 'center',
  },
  iconStyle: {
    width: ['90px', '90px', '90px', '75px', '90px'],
    pr: '20px',
  },
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: ['10px', '15px'],
  },
  featureDescription: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    mb: 0,
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default DownloadSection;
