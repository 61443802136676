import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import DocuLinkSection from '../containers/DocuLinkSection';
import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import DownloadSection from '../containers/DownloadSection';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';

export default ({ pageContext }) => {
  const { t } = useTranslation();

  const DataCients = useStaticQuery(graphql`
    query {
      saasModernJson {
        DOWNLOAD_CLIENTS {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_MOBILE {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_SERVER {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_FILESERVER {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_ADMINCLIENT {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_CICDCLIENT {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
        DOWNLOAD_OTHERRESOURCES {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Download')}
          lang={pageContext.lang}
          description={t('Download clients, servers and apps of Psono!')}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Download')}
            descriptionText={t('What do you search?')}
          />
          <DocuLinkSection />
          <DownloadSection
            titleText={t('CLIENTS')}
            descriptionText={t('Which client do you want to download?')}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_CLIENTS}
          />
          <DownloadSection
            titleText={t('MOBILE APPS')}
            descriptionText={t('Which mobile app do you want to download?')}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_MOBILE}
          />
          <DownloadSection
            titleText={t('SERVER')}
            descriptionText={t('Which server do you want to download?')}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_SERVER}
          />
          <DownloadSection
            titleText={t('FILESERVER')}
            descriptionText={t('Looking for our Fileserver module?')}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_FILESERVER}
          />
          <DownloadSection
            titleText={t('Admin Webclient')}
            descriptionText={t(
              'Looking for our admin webclient module to adminstrate your installation?'
            )}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_ADMINCLIENT}
          />
          <DownloadSection
            titleText={t('CI/CD Client')}
            descriptionText={t(
              'Do you want to integrate Psono into your CI CD pipeline?'
            )}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_CICDCLIENT}
          />
          <DownloadSection
            titleText={t('Other Resources')}
            descriptionText={t('Did not find what you were looking for?')}
            downloadItems={DataCients.saasModernJson.DOWNLOAD_OTHERRESOURCES}
          />
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
